import * as React from 'react';
import { IconProps } from '@shared/interfaces/icon.interface';

export const LinkIcon: React.FC<IconProps> = ({ width = 36, height = 36, className = 'border-2 rounded-[10px]' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    xmlSpace="preserve"
    className={className}
  >
    <image
      style={{
        stroke: 'none',
        strokeWidth: 0,
        strokeDasharray: 'none',
        strokeLinecap: 'butt',
        strokeDashoffset: 0,
        strokeLinejoin: 'miter',
        strokeMiterlimit: 4,
        isCustomFont: 'none',
        fontFileUrl: 'none',
        fill: '#000',
        fillRule: 'nonzero',
        opacity: 1,
      }}
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAvpJREFUaEPtmTusDkEUx3+3UGmIhAYFDYWGSBQiFFQe0YrQUOkkXolH0HiEaDQobiQ0VFRIUGpoRKgUqCRCIxIa+TNHJmNmd/abfdwv2U1usXtnzpz/efzPOfPNMOXPzJTrzwhgaA+OHhg9UGiBNkJoLaC/RYW6+Nu/AI+Aj3UySwAsAG4DO+oOKfj/ZeBo1f4SAA86Vt70Pg5cTIGYFIBC5mWBZZts/Q4sBH7FNk0KYBmwqokWFWs3AnuBFRVr1gGv2gTQku7/xMwD9gPXgPkR4VuA56UAdMhmxzhtAQjZZivwuAsAivl7NW4uAeWzzVngdCCsyAOiSyVsVYyWKB+yjc77FIRSEYBjwIU2NKyR4bNNSNFFAPrie+EztrkEHPEAFwF45pJ3EifIqq/dxjUJhvHlmqJhHvQOQIqfB656BUgsdhg4VQFkTgCQ8ttTvA2kqFKeGByAlN/kqqYsftJVWSl3w+tpwhi3MBocgCkg5WeBPUHiHARuuWIY66UGBeAXIxW+68CGAMBDYCcggD8rKu4gSRxruA4E/Y2BVLH6OhcAKOafurlALe83l6Tqcax7FIibgNaudlOWfQsx9BpClrAKFym43ClnCWqDiMLlHfDGCx+9x1qTXgGECWnv1jstAZY6r9wBdrnBJJbcvbPQe2ClO9Usrm/KA4WR9ff33butuRthpkEqsSWjJrMPngYaOvZFbhNy+6neQsgAxGZj5cYV4IwDJpBvM/qgXiuxz+dhQobVWBOWJrmcJ+UBI4j/ZOQM9bFu1KdEeUEWX+86z0NeK1GVsDFAMQB+vrUGQIIU79sS1x2pVqLOCyEAGWo38CS1cVIPmLwXgFncvplHcsMmxUIyzomKrvbPvlIAdrjc/BlYXDg7mwdUS0THtU8OgFwKrD0sY0HyAqskhIYY6jOw/l2S44G+r1Wylc8FoHVdX2yd8wpfJwAk1L9abOPHjB+u5cj6IaMkBxpZpO/FOTnQt06NzhsBNDJXB4tHD3Rg1EYip94DvwEo5NAx2p1ITwAAAABJRU5ErkJggg=="
      x="-30"
      y="-30"
      width="36"
      height="36"
      transform="matrix(.75 0 0 .75 24 24)"
    />
  </svg>
);
