import { useCopyToClipboard } from '@shared/hooks/useCopyToClipboard';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from './Button';

export interface ICopyToClipboardButtonProps {
  value: string;
  customButton?: ReactElement;
  options?: ButtonProps;
}
const useInitState = () => {
  const getButtonProps = (options: ButtonProps = { as: 'button' }): ButtonProps => ({
    variant: 'primary-invert-2',
    size: 'tiny',
    className: 'font-medium',
    ...options,
  });
  return [getButtonProps];
};

const CopyToClipboardButton: React.FC<ICopyToClipboardButtonProps> = ({ value, customButton, options }) => {
  const [copiedData, copyToClipboard] = useCopyToClipboard();
  const [getButtonProps] = useInitState();
  const { t } = useTranslation();

  const handelClick = () => {
    copyToClipboard(value);
  };

  return (
    <>
      {customButton ? (
        <button disabled={copiedData} onClick={handelClick}>
          {customButton}
          {copiedData && (
            <span className="absolute p-1 w-28 inset-x-0 m-auto bg-white text-xs rounded-[10px] border-black border border-solid bottom-[30px]">
              {t('receipt-share-button-copied')}
            </span>
          )}
        </button>
      ) : (
        <Button disabled={copiedData} onClick={handelClick} {...getButtonProps(options)}>
          {copiedData ? t('receipt-share-button-copied') : t('receipt-share-button')}
        </Button>
      )}
    </>
  );
};

export default CopyToClipboardButton;
