import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from '@shared/ui/buttons/Button';

type SendReceiptFeedbackButtonProps = {
  onClick: () => void;

  className?: string;

  disabled?: boolean;
};

function useComponentState(props: SendReceiptFeedbackButtonProps) {
  const { onClick, disabled, className } = props;

  return {
    onClick,
    disabled,
    className,
  };
}

export const SendReceiptFeedbackButton: React.FC<SendReceiptFeedbackButtonProps> = (props) => {
  const { onClick, disabled, className } = useComponentState(props);
  const { t } = useTranslation();

  return (
    <Button
      as="button"
      variant="rich-blue"
      size="small"
      onClick={onClick}
      className={clsx('flex justify-center', className)}
      disabled={disabled}
    >
      {t('send-feedback')}
    </Button>
  );
};
