import { PageProps } from 'gatsby';
import type { GetServerDataProps } from 'gatsby';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Receipt } from '@widgets/receipts/Receipt';
import { useNavigate } from '@hooks/useNavigate';

import { request } from '@client';
import CopyLinkBlock from '@widgets/copy-url';
import CopyToClipboardButton from '@shared/ui/buttons/CopyToClipboardButton';
import { GenericLink } from '@shared/ui/GenericLink';
import useSocialShareLinks from '@shared/hooks/useSocialShareLinks';
import { LinkIcon } from '@shared/icons/link';
import { Application } from '@widgets/cabinet/applicationCard/types';
import { ReceiptApplicationCard } from '@widgets/cabinet/applicationCard/ReceiptApplicationCard';
import { Rating, Routes } from '@shared/enums';
import config from '@config';
import { useUser } from '@shared/hooks/useUser';
import { CabinetPathButton } from '@widgets/header/buttons/HelpButtons';
import { Receipt as PurchaseReceipt } from '@store/api/types';

interface Props {
  id: string;

  provider: string;

  application: Application | null;

  feedback: Rating | null;

  volunteer: { id: number };

  /**
   * Depends on provider
   */
  receipt: PurchaseReceipt;
}

interface ReceiptPageProps extends PageProps<object, object, object, Props> {
  id: string;
}

const useButtons = (url: string, t: (arg0: string) => void) => {
  const { buttons } = useSocialShareLinks(url);
  const copyButton = useMemo(() => <CopyToClipboardButton key={url} value={url} customButton={<LinkIcon />} />, [url]);
  const helpButton = useMemo(
    () => <CabinetPathButton route={Routes.VolunteerCabinet}>{t('i-want-to-help')}</CabinetPathButton>,
    [t],
  );
  const socialButtons = useMemo(
    () =>
      buttons.map(({ link, icon: Icon }) => (
        <GenericLink key={link} type="outer" to={link}>
          <Icon className="text-black" stroke="black" width={36} height={36} />
        </GenericLink>
      )),
    [buttons],
  );
  return { copyButton, helpButton, socialButtons };
};

const ReceiptPage: React.FC<ReceiptPageProps> = ({ id, serverData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user] = useUser();
  const url = useMemo(() => window.location.href, [window.location.href]);
  const isAuthor = useMemo(() => user?.id === serverData.volunteer?.id, [serverData.volunteer, user]);

  const { copyButton, socialButtons, helpButton } = useButtons(url, t);

  if (!serverData || !serverData.receipt) {
    navigate('/404');

    return null;
  }

  return (
    <>
      <Helmet>
        <title>
          {t('receipt-for-application')} № {id}
        </title>

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />

        <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400&display=swap" rel="stylesheet" />
        <link rel="stylesheet" type="text/css" href="/css/receipt.css" />
      </Helmet>

      <div className="flex flex-col px-4 pt-6 pb-12 lg:pt-12">
        <h1 className="text-2xl text-center mb-5">{t('receipt-for-application')}</h1>
        <CopyLinkBlock description={isAuthor && t('receipt-share-description')}>
          {isAuthor ? [...socialButtons, copyButton] : helpButton}
        </CopyLinkBlock>

        <div className="flex flex-col items-center self-center lg:flex-row lg:items-start gap-5">
          <div className="shrink-0">
            <Receipt receipt={serverData.receipt} />
          </div>

          {config.features.userFeedback && serverData.application && isAuthor && (
            <div className="order-first">
              <ReceiptApplicationCard
                purchaseId={id}
                application={serverData.application}
                feedback={serverData.feedback}
                volunteerId={serverData.volunteer?.id}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReceiptPage;

export async function getServerData(props: GetServerDataProps) {
  try {
    const result = await request(`/api/purchases/${props.params?.id}`).then((res) => res.json());

    if (result.code === 404) {
      return {
        status: 404,
        props: null,
      };
    }

    return {
      status: 200,
      props: result,
    };
  } catch {
    return {
      status: 500,
      props: null,
    };
  }
}
