import React from 'react';
import { useTranslation } from 'react-i18next';

import { Rating } from '@shared/enums';
import { Text } from '@shared/ui/text';

import { ReceiptFeedbackSelector } from '@widgets/feedback/receipt-feedback-selector';

interface ModalBodyReceiptFeedbackProps {
  onRatingSelect: (rating: Rating) => void;
}

export const ModalBodyReceiptFeedback: React.FC<ModalBodyReceiptFeedbackProps> = ({ onRatingSelect }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Text component="p" className="mb-4">
        {t('hi-edopomoga')}
      </Text>
      <Text component="p" className="mb-4">
        {t('purchase-receipt-desc')}
      </Text>

      <ReceiptFeedbackSelector defaultFeedback={null} onSelect={(item) => onRatingSelect(item.value)} />
    </div>
  );
};
