import React, { useMemo } from 'react';

import { useIntegrationConfigs } from '@hooks/useIntegrationConfigs';

export interface RawReceiptProps {
  receipt: any;
  amount: string;
  type: string;
  bonusAmount: number;
}

export const RawReceipt: React.FC<RawReceiptProps> = ({ receipt, bonusAmount, amount, type }) => {
  const integrationConfigs = useIntegrationConfigs();

  const ReceiptType = useMemo(
    () =>
      integrationConfigs.flatMap((integration) => integration.shops).find((shop) => shop.name === type)?.config.receipt,
    [integrationConfigs, type],
  );

  if (!ReceiptType) {
    return null;
  }

  return <ReceiptType receipt={receipt} amount={amount} bonusAmount={bonusAmount} />;
};
