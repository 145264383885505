import React from 'react';
import { ApplicationView } from '@shared/enums';
import { ApplicationCardShortItem } from './ApplicationCardShortItem';
import { ApplicationItem } from './types';
import { ApplicationCardItemDetails } from './ApplicationCardItemDetails';
import { isDefaultView } from '@shared/utils/applications';
import { ApplicationCardItemsTitle } from './ApplicationCardItemsTitle';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  view: ApplicationView;
  items: ApplicationItem[];
};

function useActiveApplicationCardItemsState({ view, items }: Props) {
  return { view, items } as const;
}
export const ActiveOnlineApplicationCardItems = (props: Props) => {
  const { view, items } = useActiveApplicationCardItemsState(props);

  const ApplicationCardItem = isDefaultView(view) ? ApplicationCardShortItem : ApplicationCardItemDetails;

  return (
    <div className="lg:col-span-3">
      <ApplicationCardItemsTitle view={view} />

      {items.map((item) => (
        <ApplicationCardItem key={item.id} {...item} />
      ))}
    </div>
  );
};
