import React, { ReactNode } from 'react';
import './styles.css';

const CopyLinkBlock: React.FC<{ description: string | boolean; children?: ReactNode }> = ({
  description,
  children,
}) => {
  return (
    <div className="copy-link-block">
      {description && <div className="copy-link-block-description">{description}</div>}
      <div className="flex space-x-6 sm:mb-4 2xl justify-center">{children}</div>
    </div>
  );
};

export default CopyLinkBlock;
