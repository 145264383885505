import React from 'react';
import { ReceiptItem } from '@store/api/types';

import { formatAsNumber } from '@shared/utils/strings';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

type GenericReceiptItemProps = ReceiptItem;

export const GenericReceiptItem: React.FC<GenericReceiptItemProps> = ({
  name,
  translatedName,
  price,
  totalPrice,
  amount,
}) => {
  const isUkLanguage = useIsSpecificLanguage('uk');
  const isMultiple = amount > 1;

  const itemName = isUkLanguage ? name : translatedName || name;

  return (
    <div className="receipt__list-item">
      <div className="receipt__list-subitem">
        <dt className="receipt__list-name">{itemName}</dt>

        <dd className="receipt__list-value">{isMultiple ? '' : formatAsNumber(price)}</dd>
      </div>

      {isMultiple && (
        <div className="receipt__list-subitem">
          <dt className="receipt__list-name">
            {price} X {formatAsNumber(amount).toString()}
          </dt>
          <dd className="receipt__list-value">{formatAsNumber(totalPrice)}</dd>
        </div>
      )}
    </div>
  );
};
