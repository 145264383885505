import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationView } from '@shared/enums';
import { isDefaultView } from '@shared/utils/applications';
import { CustomerProfileGroups } from '@widgets/customer/profile/customer-profile-groups';

import { Customer } from './types';

interface ApplicationCardApplicantCategoriesProps {
  customer: Customer;
  view: ApplicationView;
}

export const ApplicationCardApplicantCategories: React.FC<ApplicationCardApplicantCategoriesProps> = ({
  customer,
  view,
}) => {
  const { t } = useTranslation();
  const applicantCategories = customer.groups;
  if (!isDefaultView(view) || !applicantCategories.length) return null;

  return (
    <div className="mt-6 lg:col-start-4 lg:col-span-3 lg:mt-0">
      <div className="text-black-50 text-xxs mb-2">{t('applicant-categories')}</div>
      <div className="flex flex-col items-start gap-y-4 mt-2">
        <CustomerProfileGroups groups={applicantCategories} />
      </div>
    </div>
  );
};
