import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Text } from '@shared/ui/text';

import { FeedbackItem, feedbackItems } from '../../values/feedback-items';

interface ReceiptFeedbackSelectorProps {
  defaultFeedback: FeedbackItem | null;

  onSelect?: (feedback: FeedbackItem) => void;
}

export const ReceiptFeedbackSelector: React.FC<ReceiptFeedbackSelectorProps> = ({ defaultFeedback, onSelect }) => {
  const { t } = useTranslation();
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackItem | null>(defaultFeedback);

  const onFeedbackItemClick = (feedback: FeedbackItem) => {
    if (feedback !== selectedFeedback) {
      setSelectedFeedback(feedback);

      if (onSelect) {
        onSelect(feedback);
      }
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-center space-x-10 mb-4">
        {feedbackItems.map((feedbackItem) => (
          <div key={feedbackItem.value} onClick={() => onFeedbackItemClick(feedbackItem)}>
            <feedbackItem.component
              width={75}
              height={75}
              className={clsx(
                'cursor-pointer grayscale opacity-40 transition-all',
                selectedFeedback === feedbackItem ? 'grayscale-0 opacity-100' : 'hover:grayscale-0 hover:opacity-100',
              )}
            />
          </div>
        ))}
      </div>

      {selectedFeedback && (
        <p>
          🗣️{' '}
          <Text size="sm" className="italic">
            {t(selectedFeedback?.description)}
          </Text>
        </p>
      )}
    </div>
  );
};
