import React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const AngryFaceIcon: React.FC<IconProps> = ({ width = 28, height = 28, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 128 128"
      version="1.1"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      width={width}
      height={height}
      className={className}
    >
      <g>
        <radialGradient
          id="face_1_"
          cx="63.6"
          cy="2584.9"
          r="56.96"
          gradientTransform="translate(0 -2522)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stopColor="#FDE030"></stop>
          <stop offset="0.92" stopColor="#F7C02B"></stop>
          <stop offset="1" stopColor="#F4A223"></stop>
        </radialGradient>
        <path
          fill="url(#face_1_)"
          d="M63.6 118.8c-27.9 0-58-17.5-58-55.9S35.7 7 63.6 7c15.5 0 29.8 5.1 40.4 14.4 11.5 10.2 17.6 24.6 17.6 41.5s-6.1 31.2-17.6 41.4c-10.6 9.3-25 14.5-40.4 14.5z"
        ></path>
        <path
          fill="#EB8F00"
          d="M111.49 29.67c5.33 8.6 8.11 18.84 8.11 30.23 0 16.9-6.1 31.2-17.6 41.4-10.6 9.3-25 14.5-40.4 14.5-18.06 0-37-7.35-48.18-22.94 10.76 17.66 31 25.94 50.18 25.94 15.4 0 29.8-5.2 40.4-14.5 11.5-10.2 17.6-24.5 17.6-41.4 0-12.74-3.47-24.06-10.11-33.23z"
        ></path>
      </g>
      <g>
        <g fill="#422B0D">
          <path d="M83.94 103.14a2.21 2.21 0 01-.89-.62c-9.73-10.11-25.82-10.42-35.93-.69-.23.23-.47.46-.69.69-.24.28-.55.49-.89.62-.75.28-1.6.12-2.2-.41-.59-.52-.79-1.35-.49-2.08C45.83 93 54.69 87 64.74 87s18.91 6 21.89 13.64c.3.73.1 1.56-.49 2.08-.6.53-1.45.69-2.2.42z"></path>
          <g>
            <path d="M58.14 68.82C55.9 66 46.67 60.58 43.34 59c-1.24-.57-2.53-1.03-3.85-1.37-.24-.07-.5-.1-.75-.1A2.87 2.87 0 0036 59.82a2.92 2.92 0 001.67 3.32c1.57.77 4.31 2.23 7.15 3.84A7.83 7.83 0 0041 73.71v1.46c0 4.14 3.36 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-1.46-.17c1.07-.07 2.03-.71 2.5-1.67.49-1 .35-2.19-.36-3.05z"></path>
            <path d="M93.52 59.82a2.867 2.867 0 00-2.79-2.27c-.25 0-.51.03-.75.1-1.32.33-2.61.79-3.85 1.35-3.33 1.56-12.56 7-14.8 9.8a2.84 2.84 0 00-.33 3.07c.48.97 1.43 1.6 2.51 1.67v1.63c0 4.14 3.36 7.5 7.5 7.5s7.5-3.36 7.5-7.5v-1.46c0-2.75-1.45-5.3-3.81-6.71 2.84-1.61 5.58-3.07 7.15-3.84 1.29-.56 2-1.97 1.67-3.34z"></path>
          </g>
        </g>
        <path
          fill="#896024"
          d="M48.12 68.45a2.874 2.874 0 00-3.82 1.34c-.53 1.11-.29 2.44.6 3.3 1.42.68 3.13.08 3.82-1.34.53-1.11.29-2.44-.6-3.3z"
        ></path>
        <path
          fill="#896024"
          d="M80.63 68.45a2.874 2.874 0 00-3.82 1.34c-.53 1.11-.29 2.44.6 3.3 1.42.68 3.13.08 3.82-1.34.53-1.11.29-2.44-.6-3.3z"
        ></path>
      </g>
    </svg>
  );
};
