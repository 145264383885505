import React from 'react';
import { Receipt as PurchaseReceipt } from '@store/api/types';

import { RawReceipt } from './raw/RawReceipt';
import { GenericReceipt } from './generic/GenericReceipt';

interface ReceiptProps {
  receipt: PurchaseReceipt;
}

export const Receipt: React.FC<ReceiptProps> = ({ receipt }) => {
  if (receipt.raw) {
    return (
      <RawReceipt
        type={receipt.type}
        bonusAmount={receipt.bonusAmount}
        amount={String(receipt.certificateAmount)}
        receipt={receipt.raw}
      />
    );
  }

  return <GenericReceipt receipt={receipt} />;
};
