import { useEffect, useState } from 'react';
export const useCopyToClipboard = (delay = 1500): [boolean, (textForCopy: string) => void] => {
    const [data, setData] = useState<string | null>(null);
    useEffect(() => {
        if (!data) return;
        const id = setTimeout(() => {
            setData(null);
        }, delay);
        return () => clearTimeout(id);
    }, [data])

    const copyHandler = (value: string) => {
        navigator.clipboard.writeText(value);
        setData(value);
    }

    return [!!data, copyHandler];
}