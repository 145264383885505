import { Rating } from '@shared/enums';
import { AngryFaceIcon } from '@shared/icons/angry-face';
import { SmileFaceIcon } from '@shared/icons/smile-face';

import { IconProps } from '@shared/interfaces/icon.interface';

export interface FeedbackItem {
  component: React.FC<IconProps>;
  value: Rating;
  description: string;
}

export const feedbackItems: FeedbackItem[] = [
  {
    component: SmileFaceIcon,
    value: Rating.Good,
    description: 'feedback-good',
  },
  {
    component: AngryFaceIcon,
    value: Rating.Bad,
    description: 'feedback-bad',
  },
];
