import { Pill } from '@shared/ui/Pill';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationCardItemProps } from './types';

function useApplicationCardShortItemState({ name, amount, unit }: ApplicationCardItemProps) {
  const { t } = useTranslation();

  return {
    itemName: t(name),
    amountWithUnit: `${amount}${t(unit || '')}`,
  } as const;
}

export const ApplicationCardShortItem = (props: ApplicationCardItemProps) => {
  const { itemName, amountWithUnit } = useApplicationCardShortItemState(props);

  return (
    <div className="flex items-center mt-4 first:mt-0">
      <Pill className="mr-2 bg-granny-apple">{itemName}</Pill>
      <Pill className="mr-2 bg-yellow-apple shrink-0">{amountWithUnit}</Pill>
    </div>
  );
};
