import React, { useState } from 'react';

import { Rating } from '@shared/enums';
import { useUser } from '@shared/hooks/useUser';

import { useCreatePurchaseFeedbackMutation } from '@store/api/purchases.api';

import { ActiveOnlineApplicationCardItems } from './ActiveOnlineApplicationCardItems';
import { ApplicationCardAddress } from './ApplicationCardAddress';
import { ApplicationCardAmount } from './ApplicationCardAmount';
import { ApplicationCardApplicantCategories } from './ApplicationCardApplicantCategories';
import { ApplicationCardCollapseController } from './ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from './ApplicationCardNeedsHolder';
import { ApplicationCardWhoNeeds } from './ApplicationCardWhoNeeds';
import { SendReceiptFeedbackButton } from './SendReceipFeedbackButton';
import { Application } from './types';

import { ModalReceiptFeedback } from '../volunteer/modals/ModalReceiptFeedback';

interface ReceiptApplicationCardProps {
  purchaseId: string;

  volunteerId: number;

  application: Application;

  feedback: Rating | null;
}

function useIsCollapsed() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapsedHandler = () => setIsCollapsed((isCollapsed) => !isCollapsed);

  return { isCollapsed, toggleCollapsedHandler } as const;
}

function useFeedbackModal(purchaseId: string, feedback: Rating | null) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(feedback !== null);
  const [isVisible, setIsVisible] = useState(false);
  const [createPurchaseFeedback] = useCreatePurchaseFeedbackMutation();

  const onOpen = () => setIsVisible(true);

  const onClose = () => setIsVisible(false);

  const onApprove = async (rating: Rating) => {
    try {
      await createPurchaseFeedback({ purchaseId, rating });

      setIsButtonDisabled(true);

      onClose();
    } catch (err) {
      // TODO: add info about err
    }
  };

  return { isVisible, isButtonDisabled, onOpen, onClose, onApprove };
}

function useComponentState(props: ReceiptApplicationCardProps) {
  const { purchaseId, volunteerId, feedback, application } = props;

  const [user] = useUser();
  const { isVisible, isButtonDisabled, onOpen, onClose, onApprove } = useFeedbackModal(purchaseId, feedback);
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return {
    applicationId: application.id,
    purchase: application.purchase,
    view: application.view,
    applicationCardAddressProps: {
      address: application.customer.address,
      oauth: application.customer.oauth,
    },
    applicationCardWhoNeedsProps: {
      customer: application.customer,
      items: application.items,
      view: application.view,
    },
    applicationCardAmountProps: {
      amount: application.items.length,
    },
    applicationCardItemsProps: {
      application,
    },
    applicationItems: application.items,
    collapsedProps: {
      isCollapsed,
      toggleCollapsedHandler,
    },
    feedback: {
      buttonProps: { isVisible: user?.id === volunteerId, disabled: isButtonDisabled, onClick: onOpen },
      modalProps: { isVisible, onApprove, onClose },
    },
  } as const;
}

export const ReceiptApplicationCard = (props: ReceiptApplicationCardProps) => {
  const {
    applicationCardAddressProps,
    applicationCardWhoNeedsProps,
    applicationCardAmountProps,
    applicationItems,
    collapsedProps,
    feedback,
    view,
  } = useComponentState(props);

  return (
    <>
      {feedback.buttonProps.isVisible && (
        <div className="p-[24px] lg:p-[32px] bg-white border-hawkes-blue border-2 rounded-lg">
          <div className="flex items-start justify-between w-100">
            <ApplicationCardAddress {...applicationCardAddressProps} className="mb-4" />
            <ApplicationCardCollapseController
              className="hidden lg:flex"
              onClick={collapsedProps.toggleCollapsedHandler}
              isCollapsed={collapsedProps.isCollapsed}
              view={view}
            />
          </div>
          <div className="flex flex-col items-start justify-between mb-4 w-100 lg:grid lg:grid-cols-5 lg:items-start gap-5">
            <ApplicationCardWhoNeeds
              className="col-span-3"
              {...applicationCardWhoNeedsProps}
              withoutClamp={!collapsedProps.isCollapsed}
            />
            <ApplicationCardAmount className="col-start-4 col-end-4" {...applicationCardAmountProps} />
            <div className="self-center lg:self-end">
              {feedback.buttonProps.isVisible && <SendReceiptFeedbackButton {...feedback.buttonProps} />}

              <ModalReceiptFeedback {...feedback.modalProps} />
            </div>
          </div>
          <div className="flex justify-center">
            <ApplicationCardCollapseController
              className="flex mx-auto lg:hidden"
              onClick={collapsedProps.toggleCollapsedHandler}
              isCollapsed={collapsedProps.isCollapsed}
              view={view}
            />
          </div>
          <ApplicationCardNeedsHolder isCollapsed={collapsedProps.isCollapsed}>
            <ActiveOnlineApplicationCardItems items={applicationItems} view={view} />
            <ApplicationCardApplicantCategories {...applicationCardWhoNeedsProps} />
          </ApplicationCardNeedsHolder>
        </div>
      )}
    </>
  );
};
