import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconNeedList } from '@shared/icons/IconNeedList';
import { ApplicationItem } from './types';

type ApplicationCardAmountOfItemsProps = {
  amount: ApplicationItem['amount'];
  label?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function useApplicationCardAmountState(props: ApplicationCardAmountOfItemsProps) {
  return {
    amount: props.amount,
    label: props.label ?? 'total-needs',
  } as const;
}

export const ApplicationCardAmount = (props: ApplicationCardAmountOfItemsProps) => {
  const { amount, label } = useApplicationCardAmountState(props);
  const { t } = useTranslation();

  return (
    <div className={props.className}>
      <div className="text-black-50 text-xxs mb-2">{t(label)}</div>
      <div className="flex items-center text-lg font-light">
        <IconNeedList className="mr-2 shrink-0" />({amount})
      </div>
    </div>
  );
};
