import React from 'react';
import { useTranslation } from 'react-i18next';

import { Receipt } from '@store/api/types';
import { formatAsNumber } from '@shared/utils/strings';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

import { GenericReceiptItem } from './GenericReceiptItem';
import { GenericReceiptTax } from './GenericReceiptTax';

type GenericReceiptProps = { receipt: Receipt };

const formatSum = (isUkLanguage: boolean) => (isUkLanguage ? 'грн' : 'UAH');

export const GenericReceipt: React.FC<GenericReceiptProps> = ({ receipt }) => {
  const { t } = useTranslation();
  const isUkLanguage = useIsSpecificLanguage('uk');

  const { details, items, bonusAmount, certificateAmount, totalPrice } = receipt;

  return (
    <div className="receipt-wrapper">
      <div className="receipt">
        <div className="receipt__header">
          {details?.firstAddressLine && <span className="receipt__header-item">{details.firstAddressLine}</span>}

          {details?.secondAddressLine && <span className="receipt__header-item">{details.secondAddressLine}</span>}
        </div>

        <div className="receipt__body">
          <dl className="receipt__list">
            {details?.cashier && (
              <div className="receipt__list-item">
                <div className="receipt__list-subitem">
                  <dt className="receipt__list-name">
                    {details?.checkboxNumber}

                    {details?.cashier}
                  </dt>
                  <dd className="receipt__list-value">01</dd>
                </div>
              </div>
            )}

            <div className="receipt__list-item">
              <div className="receipt__list-subitem">
                <dt className="receipt__list-name"># {t('cheque')}</dt>
                <dd className="receipt__list-value">#</dd>
              </div>
            </div>

            {items.map((item) => (
              <GenericReceiptItem key={item.name} {...item} />
            ))}

            <div className="receipt__list-item">
              <div className="receipt__list-subitem">
                <dt className="line"></dt>
              </div>

              <div className="receipt__list-subitem">
                <dt className="receipt__list-name text--uppercase">{t('sum')}</dt>

                <dd className="receipt__list-value">
                  {formatAsNumber(details?.totalPrice || totalPrice)} {formatSum(isUkLanguage)}
                </dd>
              </div>

              {(details?.tax || []).map(({ name, value, amount }) => (
                <GenericReceiptTax name={name} value={value} amount={amount} />
              ))}

              <div className="receipt__list-subitem">
                <dt className="line"></dt>
              </div>

              <div className="receipt__list-item">
                <div className="receipt__list-subitem">
                  <dt className="receipt__list-name text">{t('subscription')}</dt>
                  <dd className="receipt__list-value text">
                    {formatAsNumber(certificateAmount)} {formatSum(isUkLanguage)}
                  </dd>
                </div>

                {bonusAmount > 0 && (
                  <div className="receipt__list-subitem">
                    <dt className="receipt__list-name text">{t('subscription-bonus')}</dt>
                    <dd className="receipt__list-value text">
                      {formatAsNumber(bonusAmount)} {formatSum(isUkLanguage)}
                    </dd>
                  </div>
                )}
              </div>

              <div className="receipt__list-subitem">
                <dt className="line"></dt>
              </div>
            </div>

            <div className="receipt__list-item">
              <div className="receipt__list-subitem">
                {details?.factoryNumber && <dt className="receipt__list-name">ЗН {details?.factoryNumber || '--'}</dt>}

                {details?.fiscalNumber && <dt className="receipt__list-name">ФН {details?.fiscalNumber || '--'}</dt>}
              </div>
            </div>

            {details?.processedAt && <div className="receipt__list-item text-center">{details.processedAt}</div>}
          </dl>
        </div>

        <div className="receipt__footer">
          <span className="text text--uppercase receipt__footer-item">{t('fiscal-receipt')}</span>
        </div>
      </div>
    </div>
  );
};
