import React from 'react';

import { IconProps } from '@shared/interfaces/icon.interface';

export const SmileFaceIcon: React.FC<IconProps> = ({ width = 28, height = 28, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 128 128"
      version="1.1"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      width={width}
      height={height}
      className={className}
    >
      <g>
        <radialGradient
          id="face_1_"
          cx="63.6"
          cy="808.9"
          r="56.96"
          gradientTransform="translate(0 -746)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5" stopColor="#FDE030"></stop>
          <stop offset="0.92" stopColor="#F7C02B"></stop>
          <stop offset="1" stopColor="#F4A223"></stop>
        </radialGradient>
        <path
          fill="url(#face_1_)"
          d="M63.6 118.8c-27.9 0-58-17.5-58-55.9S35.7 7 63.6 7c15.5 0 29.8 5.1 40.4 14.4 11.5 10.2 17.6 24.6 17.6 41.5s-6.1 31.2-17.6 41.4c-10.6 9.3-25 14.5-40.4 14.5z"
        ></path>
      </g>
      <g>
        <path
          fill="#422B0D"
          d="M44 40.94c-4.19 0-8 3.54-8 9.42s3.81 9.41 8 9.41c4.2 0 8-3.54 8-9.41s-3.76-9.42-8-9.42z"
        ></path>
        <path
          fill="#896024"
          d="M43.65 44.87a2.874 2.874 0 00-3.82 1.34c-.53 1.11-.29 2.44.6 3.3 1.42.68 3.13.08 3.82-1.34.53-1.11.29-2.44-.6-3.3z"
        ></path>
        <path
          fill="#422B0D"
          d="M82.4 40.94c-4.19 0-8 3.54-8 9.42s3.81 9.41 8 9.41c4.19 0 8-3.54 8-9.41s-3.81-9.42-8-9.42z"
        ></path>
        <path
          fill="#896024"
          d="M82 44.87a2.874 2.874 0 00-3.82 1.34c-.53 1.11-.29 2.44.6 3.3 1.42.68 3.13.08 3.82-1.34.53-1.11.29-2.44-.6-3.3z"
        ></path>
        <path
          fill="#422B0D"
          d="M63 91.61c-9.07.07-17.73-3.74-23.8-10.48-.46-.53-.57-1.28-.28-1.92.29-.65.93-1.07 1.64-1.08.32 0 .64.09.92.25 4.82 2.77 12.88 6.21 21.52 6.21h.14c8.63 0 16.7-3.44 21.51-6.21.28-.16.6-.25.92-.25.71.01 1.35.43 1.64 1.08.3.64.19 1.39-.28 1.92a31.695 31.695 0 01-23.82 10.48"
        ></path>
        <path
          fill="#EB8F00"
          d="M111.49 29.67c5.33 8.6 8.11 18.84 8.11 30.23 0 16.9-6.1 31.2-17.6 41.4-10.6 9.3-25 14.5-40.4 14.5-18.06 0-37-7.35-48.18-22.94 10.76 17.66 31 25.94 50.18 25.94 15.4 0 29.8-5.2 40.4-14.5 11.5-10.2 17.6-24.5 17.6-41.4 0-12.74-3.47-24.06-10.11-33.23z"
        ></path>
      </g>
    </svg>
  );
};
