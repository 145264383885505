import React, { useState } from 'react';

import { Rating } from '@shared/enums';
import { ModalBasic } from '@shared/ui/modal/ModalBasic';

import { ModalBodyReceiptFeedback } from './bodies/ModalBodyReceiptFeedback';

interface ModalReceiptFeedbackProps {
  isVisible: boolean;

  onApprove: (rating: Rating) => void;

  onClose: () => void;
}

const useComponentState = (props: ModalReceiptFeedbackProps) => {
  const { isVisible, onApprove, onClose } = props;

  const [selectedRating, setSelectedRating] = useState<Rating | null>(null);

  const onModalApprove = () => {
    if (!selectedRating) {
      return;
    }

    onApprove(selectedRating);
  };

  return {
    modalProps: {
      title: 'send-feedback',
      isVisible,
      onClose,
      onApprove: onModalApprove,
      isApproveBtnDisabled: selectedRating === null,
      approveBtnLabel: 'confirm',
    },
    modalBodyProps: {
      onRatingSelect: setSelectedRating,
    },
  };
};

export const ModalReceiptFeedback: React.FC<ModalReceiptFeedbackProps> = (props) => {
  const { modalProps, modalBodyProps } = useComponentState(props);

  return (
    <ModalBasic {...modalProps}>
      <ModalBodyReceiptFeedback {...modalBodyProps} />
    </ModalBasic>
  );
};
