import React from 'react';

import { ReceiptDetailsTax } from '@store/api/types';
import { formatAsNumber } from '@shared/utils/strings';

type GenericReceiptTaxProps = ReceiptDetailsTax;

export const GenericReceiptTax: React.FC<GenericReceiptTaxProps> = ({ name, value, amount }) => {
  return (
    <div className="receipt__list-subitem">
      <dt className="receipt__list-name">
        <span className="text text--large-width">{name}</span> {value}
      </dt>

      <dd className="receipt__list-value">{formatAsNumber(amount)}</dd>
    </div>
  );
};
